import React, { useMemo } from "react";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { OfferComp } from "@/game-fortnite/components/Shop.jsx";
import type { Offer } from "@/game-fortnite/models/item-shop.mjs";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function HomeShopTile() {
  const {
    fortnite: { itemShopV2: itemShop },
  } = useSnapshot(readState);

  const topOffers = useMemo(() => {
    if (!itemShop) return [];
    return itemShop.sections
      .map((section) => section.offers)
      .flat()
      .filter((o) => !o.assetType.endsWith("bundle"))
      .slice(0, 4);
  }, [itemShop]);

  return (
    <GameGuideTileLg
      title={["common:navigation.itemShop", "Item Shop"]}
      description={[
        "home:guides.fortnite.shop.description",
        "Discover today's hottest items and never miss a beat in Fortnite's ever-changing store.",
      ]}
      buttonText={["common:navigation.viewItemShop", "View Item Shop"]}
      href={"/fortnite/shop"}
    >
      <div className={classNames(OfferContainer()).className}>
        {topOffers.map((offer) => {
          return (
            <OfferComp
              key={offer.offerId}
              offer={offer as Offer}
              className="offer"
              showReactions={false}
            />
          );
        })}
      </div>
    </GameGuideTileLg>
  );
}

const OfferContainer = () => css`
  padding: var(--sp-5);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: var(--sp-4);
  .offer .img-container {
    height: calc(var(--sp-10) * 3.8);
  }
`;
